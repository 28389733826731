import React from "react";
import styles from "./index.module.scss";

export const Header = () => {
  return (
    <div className={styles.container}>
      <h1>⛑ MediRatify</h1>
      <p>(A medical diagnostic software)</p>
    </div>
  );
};
