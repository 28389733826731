import React from "react";
import styles from "./index.module.scss";
import shubham from "../assets/shubhamprakash2.png";
import laal from "../assets/laal.jpeg";
import sohini from "../assets/sohini.png";

export const Footer = () => {
  return (
    <div className={styles.container}>
      <h2>Developers</h2>
      <hr />
      <div className={styles.info}>
        <div className={styles.card}>
          <img src={shubham} alt="Shubham Prakash" />
          <h3>Shubham Prakash</h3>

          <p>
            <strong>Roll:</strong> 17/IT/08
          </p>
          <p>
            <strong>University Roll:</strong> 10700217008
          </p>
          <p>
            <a href="https://github.com/i-shubhamprakash">Github</a>
          </p>
        </div>
        <div className={styles.card}>
          <img src={sohini} alt="Sohini" />
          <h3>Sohini Pattanayak</h3>
          <p>
            <strong>Roll:</strong> 17/IT/05
          </p>
          <p>
            <strong>University Roll:</strong> 10700217005
          </p>
          <p>
            <a href="https://github.com/rimmi21">Github</a>
          </p>
        </div>
        <div className={styles.card}>
          <img src={laal} alt="Shuvam" />
          <h3>Shuvam Lal</h3>
          <p>
            <strong>Roll:</strong> 17/IT/07
          </p>
          <p>
            <strong>University Roll:</strong> 10700217007
          </p>
          <p>
            <a href="https://github.com/shuvamlal9">Github</a>
          </p>
        </div>
      </div>
    </div>
  );
};
